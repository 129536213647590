<template>
  <div class="pl-2">
    <h3 class="font-weight-bold">General Settings</h3>
    <hr class="mt-0 py-0 pl-1 mb-2" style="background-color: #e2e2e2" />
    <div class="w-40">
      <div class="d-flex flex-column border-bottom pb-2 mb-2">
        <div class="d-flex align-items-center my-2">
          <div>Show prevailing farm-gate prices</div>
          <b-form-checkbox name="check-button" switch class="ml-auto">
          </b-form-checkbox>
        </div>
        <div class="d-flex align-items-center my-2">
          <div>Enable Advances</div>
          <b-form-checkbox name="check-button" switch class="ml-auto">
          </b-form-checkbox>
        </div>
        <div class="d-flex align-items-center my-2">
          <div>Enable interest on advances</div>
          <div class="d-flex ml-auto">
            <b-form-checkbox name="check-button" switch class="ml-auto">
            </b-form-checkbox>
            <b-input value="5%" class="col-3"></b-input>
          </div>
        </div>
        <div class="d-flex align-items-center my-2">
          <div>Automatically reduce amount from produce amount</div>
          <b-form-checkbox name="check-button" switch class="ml-auto">
          </b-form-checkbox>
        </div>
        <div class="d-flex align-items-center my-2">
          <div>Show farmer photos</div>
          <b-form-checkbox name="check-button" switch class="ml-auto">
          </b-form-checkbox>
        </div>
        <div class="d-flex align-items-center">
          <div>Use default seasons</div>
          <b-form-checkbox name="check-button" switch class="ml-auto">
          </b-form-checkbox>
        </div>
      </div>
      <div class="d-flex mb-2 pb-2 border-bottom">
        <div class="col-7 align-self-center">
          <h6 class="font-weight-bold">Baseline data</h6>
        </div>
        <div class="col-5">
          <b-form-select class="mb-1">
            <b-form-select-option :value="'Custom'"
              >Custom</b-form-select-option
            >
          </b-form-select>
          <b-form-select class="mb-1">
            <b-form-select-option :value="'Custom'"
              >Baseline Dataset 2016</b-form-select-option
            >
          </b-form-select>
          <b-link>Upload Dataset (csv)</b-link>
        </div>
      </div>
      <div class="d-flex mb-2 pb-2 border-bottom">
        <div class="col-7">User logs Sent to</div>
        <div class="col-5">
          <b-input type="text" placeholder="Johndoe@example.com"></b-input>
        </div>
      </div>
      <div class="d-flex flex-column">
        <div class="d-flex align-items-center">
          <div>Send SMS After payments</div>
          <b-form-checkbox name="check-button" switch class="ml-auto">
          </b-form-checkbox>
        </div>
        <div class="d-flex align-items-center">
          <div class="col-7">Auto Payment Schedule</div>
          <b-form-select  class="mb-1" v-model="auto_payment_schedule">
            <b-form-select-option value="Custom"
              >1 day after produce bought</b-form-select-option
            >
          </b-form-select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    let auto_payment_schedule="Custom";
    return {
      auto_payment_schedule
    }
  }
};
</script>

<style>
.w-40 {
  width: 40%;
}
</style>